// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aboutus-overview-js": () => import("./../../../src/pages/aboutus_overview.js" /* webpackChunkName: "component---src-pages-aboutus-overview-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-become-a-partner-js": () => import("./../../../src/pages/become-a-partner.js" /* webpackChunkName: "component---src-pages-become-a-partner-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-communications-apis-js": () => import("./../../../src/pages/communications-apis.js" /* webpackChunkName: "component---src-pages-communications-apis-js" */),
  "component---src-pages-contact-centers-js": () => import("./../../../src/pages/contact-centers.js" /* webpackChunkName: "component---src-pages-contact-centers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-copyright-policy-js": () => import("./../../../src/pages/copyright-policy.js" /* webpackChunkName: "component---src-pages-copyright-policy-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-financial-services-js": () => import("./../../../src/pages/financial-services.js" /* webpackChunkName: "component---src-pages-financial-services-js" */),
  "component---src-pages-for-analysts-js": () => import("./../../../src/pages/for-analysts.js" /* webpackChunkName: "component---src-pages-for-analysts-js" */),
  "component---src-pages-healthcare-js": () => import("./../../../src/pages/healthcare.js" /* webpackChunkName: "component---src-pages-healthcare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-language-selector-js": () => import("./../../../src/pages/language-selector.js" /* webpackChunkName: "component---src-pages-language-selector-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-log-in-js": () => import("./../../../src/pages/log-in.js" /* webpackChunkName: "component---src-pages-log-in-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-media-coverage-js": () => import("./../../../src/pages/media-coverage.js" /* webpackChunkName: "component---src-pages-media-coverage-js" */),
  "component---src-pages-nada-js": () => import("./../../../src/pages/nada.js" /* webpackChunkName: "component---src-pages-nada-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-patents-js": () => import("./../../../src/pages/patents.js" /* webpackChunkName: "component---src-pages-patents-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-providers-js": () => import("./../../../src/pages/providers.js" /* webpackChunkName: "component---src-pages-providers-js" */),
  "component---src-pages-public-sector-js": () => import("./../../../src/pages/public-sector.js" /* webpackChunkName: "component---src-pages-public-sector-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-retail-ecommerce-js": () => import("./../../../src/pages/retail-ecommerce.js" /* webpackChunkName: "component---src-pages-retail-ecommerce-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-sweepsakes-rules-js": () => import("./../../../src/pages/sweepsakes-rules.js" /* webpackChunkName: "component---src-pages-sweepsakes-rules-js" */),
  "component---src-pages-system-status-js": () => import("./../../../src/pages/system-status.js" /* webpackChunkName: "component---src-pages-system-status-js" */),
  "component---src-pages-transportation-logistics-js": () => import("./../../../src/pages/transportation-logistics.js" /* webpackChunkName: "component---src-pages-transportation-logistics-js" */),
  "component---src-pages-unified-communications-js": () => import("./../../../src/pages/unified-communications.js" /* webpackChunkName: "component---src-pages-unified-communications-js" */),
  "component---src-pages-vonage-cares-js": () => import("./../../../src/pages/vonage-cares.js" /* webpackChunkName: "component---src-pages-vonage-cares-js" */),
  "component---src-pages-vonage-stories-js": () => import("./../../../src/pages/vonage-stories.js" /* webpackChunkName: "component---src-pages-vonage-stories-js" */),
  "component---src-pages-why-vonage-js": () => import("./../../../src/pages/why-vonage.js" /* webpackChunkName: "component---src-pages-why-vonage-js" */)
}

